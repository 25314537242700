<script setup lang="ts">
import XHeader from "@ui/XHeader.vue";
import XStep from "@ui/XStep.vue";
import XButton from "@ui/XButton.vue";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import XCard from "@ui/XCard.vue";
import { API_URL } from "@config/core";

const route = useRoute();
let session = ref();
const isSetActive = ref(false);

async function handleLoadSessionById(sessionId: string) {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const res = await fetch(`${API_URL}/session/${sessionId}`, {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
      credentials: "include",
    });

    const response = await res.json();

    return response;
  } catch (e) {
    console.error("handleLoadSessionById ", e);
  }
}

let currentInterval: string | number | NodeJS.Timeout | undefined;

function handleStartInterval() {
  currentInterval = setInterval(async () => {
    isSetActive.value = true;

    if (!Array.isArray(route?.params?.id) && route?.params?.id) {
      const res = await handleLoadSessionById(route.params.id);
      session.value = res;
    }
  }, 5000);

  return;
}

function handleStopInterval() {
  clearInterval(currentInterval);
  isSetActive.value = false;
}

// here debug
watch(session, (newSession) => {
  const allDone = newSession?.sessionCardSets.every(
    // @ts-ignore
    (item: unknown) => item?.isDone === true
  );

  if (
    (newSession?.currentActiveSessionCardSet &&
      newSession?.currentActiveSessionCardSet?.done) ||
    allDone
  ) {
    handleStopInterval();
  }
});

async function handleActivateSet(cardSetId: number) {
  try {
    isSetActive.value = true;
    handleStartInterval();

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const res = await fetch(
      `${API_URL}/session/${session.value.sessionId}/card-set/set-active/${cardSetId}`,
      {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        credentials: "include",
      }
    );

    await res.text();
  } catch (error) {
    console.error(error);
  }
  return;
}

// function handleActiveSetInterval(cardSetId: number) {
//   let currentInterval;
//   let currentSet;
//   currentInterval = setInterval(async () => {
//     await handleActivateSet(cardSetId);
//     currentSet = session.value?.sessionCardSets?.find(
//       (item) => item.id === cardSetId
//     );
//   }, 5000);

//   // clear Interval
//   if (currentSet.isDone) {
//     clearInterval(currentInterval);
//   }

//   return;
// }

function handleCopySessionLink(sessionId: string) {
  const origin = window.location.origin;
  const clientLink = `${origin}/customer/session/${sessionId}`;

  navigator.clipboard.writeText(clientLink);
  return;
}

onMounted(async () => {
  if (!Array.isArray(route?.params?.id) && route?.params?.id) {
    const res = await handleLoadSessionById(route.params.id);
    session.value = res;
  }
});

// const clientLink = computed(
//   () => `https://psy.otoselo.com/customer/session/${session.value.sessionId}`
// );
</script>

<template>
  <XHeader title="Сеанс" />

  <div class="copy-link">
    <XButton
      title="Скопіювати посилання"
      @click="handleCopySessionLink(session.sessionId)"
    />
    <div>щоб відправити клієнту</div>
    <br />
    <XButton title="Stop" @click="handleStopInterval" />
  </div>

  <XStep v-for="item in session?.sessionCardSets">
    <div v-if="!item.isDone" style="text-align: center; margin-bottom: 1rem">
      <XButton
        title="Активувати набір"
        :disabled="isSetActive"
        @click="handleActivateSet(item.id)"
      />
    </div>

    <div>
      <b>{{ item.cardSet.name }}</b>
    </div>

    <div>Потрібно обрати — {{ item.requiredCardsNumber }}</div>

    <div v-if="item.showCardsFaceUp">Показувати у відкриту</div>

    <div v-else>Показувати у закриту</div>

    <div class="card-rows">
      <div
        v-for="card in item.selectedCards"
        :class="{
          'card-selected':
            item?.customerSelection.length &&
            item?.customerSelection.indexOf(card) >= 0,
        }"
      >
        <XCard :src="`/cards/${item.cardSet.id}/${card}.jpg`" />
      </div>
    </div>
  </XStep>
</template>

<style scoped lang="scss">
@use "src/scss/vars" as *;

.card-rows {
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  margin: 1rem 0;
}

.card {
  &-selected {
    border: 3px solid $green;
  }
}

.card-image {
  width: 100%;
  height: auto;
  border-radius: 1rem;
}

.copy-link {
  text-align: center;
}
</style>
