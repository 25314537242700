<script setup lang="ts">
import { computed, reactive, ref, watch } from "vue";
import XWhiteBlock from "@ui/XWhiteBlock.vue";
import useSetBuilderFrame from "./useSetBuilderFrame";
import DeckChoice from "./DeckChoice.vue";
import XButton from "@ui/XButton.vue";
import { SessionSet } from "@type/session";
import { ButtonNavigation } from "@type/setBuilderFrame";
import CardChoiceMode from "./CardChoiceMode.vue";
import RequiredNumber from "./RequiredNumber.vue";
import SetSummary from "./SetSummary.vue";

const emit = defineEmits<{
  (e: "add-card-set-to-session", sessionSet: SessionSet): void;
}>();

const { sessionSetup, patchSessionSetup } = useSetBuilderFrame();

const buttons = reactive<ButtonNavigation>({
  back: null,
  next: null,
});

const backButtonChain = ref<Partial<SessionSet>[]>([]);

function patchDeckInSessionSetup(buttonNavigation: ButtonNavigation) {
  buttons.back = buttonNavigation.back;
  buttons.next = buttonNavigation.next;

  return;
}

function handleNextButton() {
  patchSessionSetup(buttons.next);
  buttons.next = null;
  backButtonChain.value.unshift(buttons.back);

  return;
}

function handleBackButton() {
  patchSessionSetup(backButtonChain.value[0]);
  buttons.next = null;
  backButtonChain.value.shift();

  return;
}

const isLastStep = computed(
  () =>
    sessionSetup.value.useAllCards !== null &&
    sessionSetup.value.showCardsFaceUp !== null
);

function lastStepHandle() {
  console.log("lastStepHandle");
  emit("add-card-set-to-session", sessionSetup.value);
}

watch(sessionSetup, (sessionSet) => {
  if (sessionSet.showCardsFaceUp !== null) {
    emit("add-card-set-to-session", sessionSet);
  }
});
</script>

<template>
  <XWhiteBlock class="space-block">
    <SetSummary
      :id="sessionSetup.setId"
      :cards="sessionSetup.cards"
      :use-all-cards="sessionSetup.useAllCards"
      class="set-position"
    />

    <div class="set-frame">
      <div class="set-frame__content-border">
        <div class="set-frame__content">
          <DeckChoice
            v-if="!sessionSetup.setId"
            @select-deck="patchDeckInSessionSetup"
          />

          <CardChoiceMode
            v-if="sessionSetup.setId && sessionSetup.useAllCards === null"
            :set-id="sessionSetup.setId"
            @select-card-mode="patchDeckInSessionSetup"
          />

          <RequiredNumber
            v-if="
              sessionSetup.setId &&
              sessionSetup.useAllCards !== null &&
              sessionSetup.requiredNumber === 0
            "
            :cards-number="sessionSetup.cards.length"
            @required-number="patchDeckInSessionSetup"
          />
        </div>
      </div>

      <div class="set-frame__bottom">
        <XButton
          title="назад"
          :disabled="!backButtonChain.length"
          @click="handleBackButton"
        />

        <XButton
          title="далі"
          :disabled="!buttons.next && !isLastStep"
          @click="
            () =>
              sessionSetup.showCardsFaceUp !== null
                ? lastStepHandle()
                : handleNextButton()
          "
        />
      </div>
    </div>
  </XWhiteBlock>
</template>

<style scoped lang="scss">
@use "src/scss/vars" as *;

.set-frame {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.set-frame__content-border {
  flex: 1;
  position: absolute;
  top: 1rem;
  left: 0;
  box-sizing: border-box;
  overflow: auto;
  height: calc(100% - 5rem);
  width: 100%;
}

.set-frame__content {
  padding: 0 1rem 1rem 1rem;
  width: 100%;
  box-sizing: border-box;
}

.set-frame__bottom {
  height: 3rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem 1rem 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

.space-block {
  margin: 1rem 1rem 7rem 1rem;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.set-position {
  position: absolute;
  top: -0.6rem;
  left: 1rem;
}
</style>
