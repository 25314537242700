<script setup lang="ts">
import { reactive, ref } from "vue";
import XCheckbox from "@ui/XCheckbox.vue";
import XRadio from "@ui/XRadio.vue";
import XRange from "@ui/XRange.vue";
import XInput from "@ui/XInput.vue";
import { cardDeck } from "@config/cardDecks";
import SelectCards from "./SelectCards.vue";
import { ButtonNavigation } from "@type/setBuilderFrame";

const emit = defineEmits<{
  (e: "select-card-mode", buttonNavigation: ButtonNavigation): void;
}>();

const buttonNavigation = reactive<ButtonNavigation>({
  back: {
    useAllCards: null,
    cards: [],
  },
  next: {
    useAllCards: null,
    cards: [],
  },
});

const props = defineProps<{
  setId: number;
}>();

const useAllCards = ref(undefined);
const cardsChoice = ref("");
const quantity = ref(2);
const deckQuantity =
  cardDeck.find((deck) => deck.id === props.setId)?.quantity || 0;

function selectCardsHandle(cards: number[]) {
  buttonNavigation.next.cards = cards;
  buttonNavigation.next.useAllCards = false;

  emit("select-card-mode", buttonNavigation);

  return;
}

function useAllCardsHandle() {
  if (useAllCards.value) {
    buttonNavigation.next.useAllCards = true;

    buttonNavigation.next.cards = [];

    for (let i = 1; i <= deckQuantity; i++) {
      buttonNavigation.next.cards.push(i);
    }

    emit("select-card-mode", buttonNavigation);

    return;
  }
}

function randomCardsChoiceHandle() {
  const cards: number[] = [];
  const availableCards = Array.from({ length: deckQuantity }, (_, i) => i + 1);

  for (let i = 0; i < quantity.value; i++) {
    const randomIndex = Math.floor(Math.random() * availableCards.length);
    cards.push(availableCards.splice(randomIndex, 1)[0]);
  }

  selectCardsHandle(cards);

  return;
}
</script>

<template>
  <div
    class="deck-quantity-choice"
    :class="{ 'deck-quantity-choice--disabled': useAllCards }"
  >
    <div class="label">калькість карт</div>

    <div class="range">
      <div class="range__number-cell">
        <XInput
          type="number"
          name="rangeInput"
          v-model:value="quantity"
          :disabled="useAllCards"
        />
      </div>
      <div class="range__manual-cell">
        <div>від 2</div>
        <XRange
          :min="2"
          :max="deckQuantity"
          :disabled="useAllCards"
          v-model:value="quantity"
          class="range__manual-input"
        />
        <div>до {{ deckQuantity }}</div>
      </div>
    </div>

    <div class="choice">
      <div>
        <XRadio
          :disabled="useAllCards || quantity === 0"
          label="вибрати карти випадково"
          value="random"
          v-model:modelValue="cardsChoice"
          class="choice__random"
          @update:modelValue="randomCardsChoiceHandle"
        />
      </div>
      <div>
        <XRadio
          :disabled="useAllCards || quantity === 0"
          label="вибрати карти самостійно"
          value="manual"
          v-model:modelValue="cardsChoice"
        />
      </div>
    </div>
  </div>

  <div class="label">або</div>
  <XCheckbox
    label="задіяти всю колоду"
    v-model:value="useAllCards"
    @update:value="useAllCardsHandle"
    style="margin-bottom: 2rem"
  />

  <SelectCards
    v-if="cardsChoice === 'manual' && quantity > 0 && quantity !== deckQuantity"
    :setId="setId"
    :requiredQuantity="quantity"
    :deckQuantity="deckQuantity"
    @card-numbers="selectCardsHandle"
  />
</template>

<style scoped lang="scss">
@use "/src/scss/vars" as *;

.deck-quantity-choice--disabled {
  opacity: 0.5;
}

.range {
  display: flex;
  flex-direction: column;
  column-gap: 2rem;

  @include screen($md) {
    flex-direction: row;
  }
}

.range__manual-input {
  width: 100%;
}

.range__manual-cell {
  display: flex;
  column-gap: 1rem;
  align-items: center;
  font-size: $font-size-small;
  font-weight: bold;
  text-wrap: nowrap;
  margin-bottom: 1rem;
}

.label {
  text-align: center;
  margin-bottom: 1rem;
  font-weight: bold;

  @include screen($md) {
    text-align: left;
  }
}

.choice {
  margin-bottom: 1rem;
}

.choice__random {
  margin-bottom: 0.5rem;
}
</style>
