<script setup lang="ts">
import { computed, reactive, ref } from "vue";
import XInput from "@ui/XInput.vue";
import XHeader from "@ui/XHeader.vue";
import XButton from "@ui/XButton.vue";
import XFormBorder from "@ui/XFormBorder.vue";
import { useRoute } from "vue-router";
import { API_URL } from "@config/core";

// import { useAuthStore } from "@store/useAuthStore.ts";
// import { useErrorStore } from "@store/useErrorStore";
// import { useRouter } from "vue-router";
// import { storeToRefs } from "pinia";
// import XError from "@ui/XError.vue";
// import XTransitionFadeIn from "@ui/XTransitionFadeIn.vue";

// const { setAuth } = useAuthStore();
// const router = useRouter();

// const errorStore = useErrorStore();
// const { error } = storeToRefs(errorStore);
// const { setAuthError } = errorStore;

const route = useRoute();

const form = reactive({
  email: "",
});

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

const formEmailIsValid = computed(() => {
  if (!form.email) return false;
  return emailRegex.test(form.email);
});

const passwordForm = reactive({
  password: {
    password: "",
    confirm_password: "",
  },
});

const submitEmailButtonIsDisabled = ref(false);
const submitEmailIsOk = ref(false);

async function handleSubmitEmail() {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(form),
      redirect: "follow",
      credentials: "include",
    };

    submitEmailButtonIsDisabled.value = true;

    const res = await fetch(
      `${API_URL}/reset-password/reset-password-request`,
      // @ts-ignore
      requestOptions
    )
      .then((response) => {
        return response;
      })
      .catch((error) => console.log("error", error));

    if (res?.ok) submitEmailIsOk.value = true;
  } catch (error) {
    console.error(error);
  }
}

const handleFormEmailInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  form.email = target.value;
};

async function handleSubmitPassword() {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(passwordForm),
      redirect: "follow",
      credentials: "include",
    };

    await fetch(
      `${API_URL}/reset-password/reset/${route.params.token}`,
      // @ts-ignore
      requestOptions
    )
      .then((response) => {
        return response;
      })
      .catch((error) => console.log("error", error));
  } catch (error) {
    console.error(error);
  }
}
</script>

<template>
  <XHeader
    :title="
      route.params?.token?.length > 0 ? 'Новий пароль' : 'Відновлення паролю'
    "
  />

  <!-- <XTransitionFadeIn v-if="error?.auth?.value">
    <XError class="error-message">
      {{ error.auth?.message }}
    </XError>
  </XTransitionFadeIn> -->

  <XFormBorder v-if="route.params?.token?.length > 0">
    <div style="display: flex">
      <div>
        <XInput
          v-model:value="passwordForm.password.password"
          name="password"
          type="password"
          label="Новий пароль"
        />
      </div>
      <div>&nbsp;</div>
    </div>

    <div style="display: flex">
      <div>
        <XInput
          v-model:value="passwordForm.password.confirm_password"
          name="confirm_password"
          type="password"
          :label="
            passwordForm.password.confirm_password ===
              passwordForm.password.password &&
            passwordForm.password.password.length > 0
              ? 'Новий пароль ще раз &check;'
              : 'Новий пароль ще раз'
          "
        />
      </div>
    </div>

    <XButton
      title="Змінити пароль"
      :disabled="
        passwordForm.password.confirm_password !==
          passwordForm.password.password ||
        passwordForm.password.password.length === 0
      "
      @click="handleSubmitPassword"
    />
  </XFormBorder>

  <XFormBorder v-if="!route.params?.token && !submitEmailIsOk">
    <XInput
      v-model:value="form.email"
      name="login"
      type="email"
      :label="
        formEmailIsValid ? 'Електронна пошта &check;' : 'Електронна пошта'
      "
      @input="handleFormEmailInput"
    />

    <XButton
      title="Продовжити"
      :disabled="!formEmailIsValid || submitEmailButtonIsDisabled"
      @click="handleSubmitEmail"
    />
  </XFormBorder>

  <XFormBorder v-if="submitEmailIsOk">
    <p><b>Посилання для відновлення паролю відправлено на пошту.</b></p>
    <p>
      Будь-ласка перейдіть за посиланням у вашій поштовій скринці для
      відновлення доступу до вашого облікового запису.
    </p>
  </XFormBorder>
</template>
