<script setup lang="ts"></script>

<template>
  <div class="block-bar">
    <slot />
  </div>
</template>

<style scoped lang="scss">
@use "/src/scss/vars" as *;

.block-bar {
  border-radius: 0.6rem;
  height: 1.2rem;
  width: fit-content;
  background-color: $blue;
  padding: 0 1rem;
  color: white;
  font-size: 0.84rem;
  font-weight: bold;
  line-height: 1.2rem;
}
</style>
