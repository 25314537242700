declare global {
  interface Window {
    apiUrl?: string;
    coreUrl?: string;
  }
}

export const CORE_URL = window.coreUrl;
export const url = window.apiUrl;
export const API_URL = window.apiUrl;
