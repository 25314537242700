<script setup lang="ts">
import { reactive } from "vue";
import { useRouter } from "vue-router";
import XInput from "@ui/XInput.vue";
import XHeader from "@ui/XHeader.vue";
import XButton from "@ui/XButton.vue";
import XFormBorder from "@ui/XFormBorder.vue";
import { storeToRefs } from "pinia";
import XError from "@ui/XError.vue";
import XTransitionFadeIn from "@ui/XTransitionFadeIn.vue";
import { useAuthStore } from "@store/useAuthStore";
import { useErrorStore } from "@store/useErrorStore";
import { API_URL } from "@config/core";

const { setAuth } = useAuthStore();
const router = useRouter();

const errorStore = useErrorStore();
const { error } = storeToRefs(errorStore);
const { setRegisterError } = errorStore;

const form = reactive({
  email: "",
  password: {
    password: "",
    confirm_password: "",
  },
});

async function handleSubmit() {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(form),
      redirect: "follow",
      credentials: "include" as RequestCredentials,
    };

    // @ts-ignore
    const res = await fetch(`${API_URL}/register`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .catch((error) => console.log("error", error));
    console.log("🚀 ~ handleSubmit ~ res:", res);

    if (
      res?.email.length > 0 ||
      res?.password.length > 0 ||
      res?.confirm_password.length > 0
    ) {
      setRegisterError(true, res);
      setAuth(false);
    } else {
      setAuth(true);
      setRegisterError(false);
      router.push("/"); // or to push route from local storage
    }
  } catch (error) {
    console.error(error);
  }
}
</script>

<template>
  <XHeader title="Реєстрація" />

  <XTransitionFadeIn v-if="error?.register?.value">
    <XError v-for="message in error.register?.message" class="error-message">{{
      message
    }}</XError>
  </XTransitionFadeIn>

  <XFormBorder>
    <XInput
      v-model:value="form.email"
      name="email"
      type="email"
      label="Електронна пошта"
    />

    <XInput
      v-model:value="form.password.password"
      name="password"
      type="password"
      label="Пароль"
    />

    <XInput
      v-model:value="form.password.confirm_password"
      name="confirmPassword"
      type="password"
      label="Ще раз пароль"
    />

    <XButton @click.prevent="handleSubmit" title="Відправити" />
  </XFormBorder>
</template>

<style scoped></style>
