export const deckSet = {
  ohVerlag: {
    name: "OH Verlag",
    quantity: 88,
  },
  morena: {
    name: "Morena",
    quantity: 90,
  },
};

export type CardDeck = {
  id: number;
  name: string;
  quantity: number;
};

export const cardDeck: CardDeck[] = [
  {
    id: 1,
    name: "Moreńa",
    quantity: 89,
  },
  {
    id: 2,
    name: "OH Verlag",
    quantity: 88,
  },
  {
    id: 3,
    name: "Cope",
    quantity: 88,
  },
  {
    id: 4,
    name: "Persona",
    quantity: 77,
  },
];
