<script setup lang="ts">
import { ref, reactive } from "vue";
import XCard from "@ui/XCard.vue";
import { cardDeck } from "@config/cardDecks";
import { ButtonNavigation } from "@type/setBuilderFrame";

const emit = defineEmits<{
  (e: "select-deck", buttonNavigation: ButtonNavigation): void;
}>();

const setId = ref(0);

const buttonNavigation = reactive<ButtonNavigation>({
  back: {
    setId: 0,
  },
  next: {
    setId: 0,
  },
});

function handleDeckSelect(id: number) {
  setId.value = id;
  buttonNavigation.next.setId = id;

  emit("select-deck", buttonNavigation);

  return;
}
</script>

<template>
  <div class="deck-list">
    <div
      v-for="deck in cardDeck"
      v-on="
        deck.id !== setId || setId === 0
          ? { click: () => handleDeckSelect(deck.id) }
          : {}
      "
      class="deck"
    >
      <XCard :title="String(deck.quantity)" :is-active="deck.id === setId" />

      <div
        class="deck__title"
        :class="{ deck__title_selected: deck.id === setId }"
      >
        {{ deck.name }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use "/src/scss/vars" as *;

.deck {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 0;
  padding: 0;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  min-width: 100px;
  row-gap: 0.5rem;
}

.deck-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: 2rem;
  column-gap: 1rem;

  @include screen($md) {
    row-gap: 1.5rem;
    column-gap: 1.5rem;
  }

  @include screen($lg) {
    row-gap: 2rem;
    column-gap: 2rem;
  }
}

.deck__title {
  color: $black;
  font-weight: bold;
  font-size: $font-size-small;
  transition: all 100ms ease-in-out;
}

.deck__title_selected {
  color: $blue;
  border-radius: 0.5rem;
  transition: all 100ms ease-in-out;
}
</style>
